<template>
  <v-container
    fluid
  >
    <v-card
      :style="`height: ${($vuetify.breakpoint.height - 72).toString() + 'px'}; overflow-x: hidden;`"
      color="lightBackground"
    >
      <div class="text-center pt-4">
        <h2>
          {{ $t('expressions|lavvira_extra_storage_plan') }}
        </h2>
        <h4>
          {{ $t('expressions|storage_plans_start_at') + ' ' + cfg.preices.storageHundredGbPreis + '€' }}
        </h4>
      </div>
      <div>
        <p class="text-center mx-4">
          {{ $t('expressions|upgrading_to_extra_storage_plan') }}
        </p>
      </div>
      <v-row
        justify="center"
        class="mt-2"
      >
        <v-col
          xs="12"
          md="2"
          lg="3"
        >
          <v-card
            hover
            class="mb-6"
            min-height="500"
            max-width="300"
          >
            <v-card-title class="justify-center mb-n12 pt-12">
              {{ $t('common|inclusive') }}
            </v-card-title>
            <v-card-title class="justify-center">
              <h2>5 GB</h2>
            </v-card-title>
            <v-card-text class="text-center">
              <v-btn
                disabled
                small
                width="250"
              >
                {{ $t('expressions|included_in_account') }}
              </v-btn>
            </v-card-text>
            <!-- <v-divider class="mx-2 divider-position" />
            <v-card-actions class="element-position">
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">5 GB Storage</span>
            </v-card-actions> -->
          </v-card>
        </v-col>
        <v-col
          xs="12"
          md="2"
          lg="3"
        >
          <v-card
            elevation="5"
            outlined
            class="mb-6"
            min-height="500"
            max-width="300"
          >
            <v-card-title class="justify-center">
              <p>{{ $t('common|recommended') }}</p>
            </v-card-title>
            <v-card-title class="justify-center mt-n12 mb-n12">
              {{ account.availableStorage === 100 ? $t('common|current_plan') : $t('common|standard') }}
            </v-card-title>
            <v-card-title class="justify-center">
              <h2>100 GB</h2>
            </v-card-title>
            <v-card-text class="text-center">
              <v-btn
                color="primary"
                small
                width="250"
                :disabled="storageRuleHundred()"
                @click="buyStorage(100)"
              >
                {{ getButtonText(100) }}
              </v-btn>
              <!-- <p class="mt-2">
                Or prepay annually<br>(save 16%):
              </p>
              <v-btn
                text
                small
              >
                FREE during beta testing
              </v-btn> -->
            </v-card-text>
            <!-- <v-divider class="mx-2 divider-position" />
            <v-card-actions class="align-end element-position">
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">100 GB Storage</span>
            </v-card-actions> -->
          </v-card>
        </v-col>
        <v-col
          xs="12"
          md="2"
          lg="3"
        >
          <v-card
            hover
            class="mb-6"
            min-height="500"
            max-width="300"
          >
            <v-card-title class="justify-center mb-n12 pt-12">
              {{ $t('common|advanced') }}
            </v-card-title>
            <v-card-title class="justify-center">
              <h2>1000 GB</h2>
            </v-card-title>
            <v-card-text class="text-center">
              <v-btn
                color="primary"
                small
                width="250"
                :disabled="storageRuleThousand()"
                @click="buyStorage(1000)"
              >
                {{ getButtonText(1000) }}
              </v-btn>
              <!-- <p class="mt-2">
                Or prepay annually<br>(save 16%):
              </p>
              <v-btn
                text
                small
              >
                FREE during beta testing
              </v-btn> -->
            </v-card-text>
            <!-- <v-divider class="mx-2 divider-position" />
            <v-card-actions class="align-end element-position">
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">1000 GB Storage</span>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import autobahn from 'autobahn'
import cfg from '@/config'

export default {
  data () {
    return {
      cfg
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  methods: {
    ...mapActions({
      getAndSetAccount: 'account/getAndSetAccount',
      addToast: 'toasts/addToast'
    }),
    getButtonText (amount) {
      if (this.company && this.company.subscriptionStatus === 'beta') return 'Not available fo beta'
      else return (amount === 1000 ? this.cfg.preices.storageThousandGbPreis : this.cfg.preices.storageHundredGbPreis) + '€' + ' ' + '/' + ' ' + this.$t('common|per_month')
    },
    storageRuleHundred () {
      if (this.account && this.account.subscriptionStatus === 'beta') return true
      else if (this.account && this.account.availableStorage === 100) return true
      else return false
    },
    storageRuleThousand () {
      if (this.account && this.account.subscriptionStatus === 'beta') return true
      else if (this.account && this.account.availableStorage === 1000) return true
      else return false
    },
    async buyStorage (amount) {
      return new Promise(async (resolve, reject) => {
        try {
          var connection = new autobahn.Connection({
            url: process.env.VUE_APP_CROSSBAR_PATH,
            realm: process.env.VUE_APP_CROSSBAR_REALM
          })
          connection.onopen = async (session) => {
            const result = await session.call('lavvira.stripe_controller.update_subscription_storage',
              [
                this.account._id,
                'account',
                amount
              ])
            const res = JSON.parse(result)
            if (res.success) {
              await this.getAndSetAccount()
              this.addToast({
                title: this.$t('expression|successfully_updated_your_storage'),
                color: 'white',
                snackbarColor: 'success'
              })
            } else {
              if (res.error_message === 'error|storage_exceeded') {
                this.addToast({
                  title: this.$t('error|storage_exceeded'),
                  color: 'white',
                  snackbarColor: 'error'
                })
              } else {
                this.addToast({
                  title: this.$t('error|something_went_wrong'),
                  color: 'white',
                  snackbarColor: 'error'
                })
              }
            }
          }
          connection.open()
        } catch (e) {
          console.log(e, 'EEEEEE')
          reject(e)
        }
      })
    }
    // async cancelSubscription () {
    //   const res = await this.$dialog.confirm({
    //     title: this.$t('common|warning'),
    //     text: this.$t('common|are_you_sure') + ' ' + this.$t('warning|suspending_payments')
    //   })
    //   if (!res) return
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       var connection = new autobahn.Connection({
    //         url: process.env.VUE_APP_CROSSBAR_PATH,
    //         realm: process.env.VUE_APP_CROSSBAR_REALM
    //       })
    //       connection.onopen = async (session) => {
    //         const result = await session.call('lavvira.stripe_controller.cancel_subscription',
    //           [
    //             this.account._id,
    //             'account'
    //           ])
    //         if (result === 'success') {
    //           await this.getAndSetAccount()
    //           this.addToast({
    //             title: this.$t('payments|successful_payment_cancelation'),
    //             color: 'white',
    //             snackbarColor: 'success'
    //           })
    //           this.$vuetify.theme.themes.light.primary = '#546E7A'
    //         } else {
    //           this.addToast({
    //             title: this.$t('error|error_cancel_subscription'),
    //             color: 'white',
    //             snackbarColor: 'error'
    //           })
    //         }
    //       }
    //       connection.open()
    //     } catch (e) {
    //       reject(e)
    //     }
    //   })
    // }
  }
}
</script>

<style scoped>
.divider-position {
  position: absolute;
  width: 90%;
  bottom: 75px;
}
.element-position {
  position: absolute;
  width: 90%;
  bottom: 30px;
}
</style>
